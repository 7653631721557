.content {
  position: relative;
  margin-top: 50px;
  max-height: 337px;
  box-shadow: 0px 58.1116px 45.6591px -25.5968px rgba(29, 32, 33, 0.64);
  font-family: 'RS';
  font-style: normal;
  font-weight: 400;
  font-size: 81.1325px;
  line-height: 83px;
  overflow: hidden;
  // border: 2px solid rgba(62, 62, 62, 0.07);
  background: linear-gradient(to bottom, rgba(255,255,255,0.4) 0%,rgba(255,255,255,0.1) 100%);

  text-align: center;
  color: transparent;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.56);
  backdrop-filter: blur(16.351px);
  -webkit-backdrop-filter: blur(16.351px);
  border-radius: 31.5px;
  z-index: 15;
  img {
    width: 100%;
    position: relative;
    padding: 5px;
    max-height: 337px;
    z-index: 1;
    overflow: hidden;
  }
  a {
    width: 100%;
    margin: 0;
    display: block;
    outline: none;
    user-select: none;
    border: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    z-index: -3;
    background: #0b0b0b;
    // border: 2px solid rgba(62, 62, 62, 0.9);
    // border-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%);;
    border-radius: 33px;
  }
}

@media screen and (max-width: 1230px) {
  .content {
    max-width: 900px;
    margin: 50px auto 0;
    max-height: 274px;
  }
}

@media screen and (max-width: 900px) {
  .content {
    height: 29vw;
  }
  .content img {
    height: 29vw;
  }
}

@media screen and (max-width: 768px) {
  .content {
     display: none;
  } 
 }