.services {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.serviceItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  max-width: 285px;
  width: 285px;
  max-height: 421px;
  min-height: 421px;
  margin-top: 100px;
  margin-right: 17px;
  padding: 0 22px 59px;
  z-index: -1;
  box-shadow: 0px 58.111610412597656px 45.659122467041016px -25.596782684326172px rgba(29, 32, 33, 0.64);
  backdrop-filter: blur(21.5px);
  border-radius: 22px;
  &:last-child {
    margin-right: 0;
  }
  &:nth-child(2n) {
    &::before {
      transition: all 0.2s;
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background: linear-gradient(
        0deg,
        rgba(176, 149, 255, 0) 0.06%,
        rgba(176, 149, 255, 0.53) 92.98%
      );
    }
  }
  &::before {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(
      180deg,
      rgba(176, 149, 255, 0) 0.06%,
      rgba(176, 149, 255, 0.53) 92.98%
    );
  }
  &::after {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 15px;
    background: #090808;
  }
}

.serviceTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "RS";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 18px;
  color: #ffffff;
  min-height: 45px;
}

.serviceDescription {
  max-width: 240px;
  font-family: "RS";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  min-width: 236px;
  // text-align: center;
  // letter-spacing: 0.6px;
  color: #ffffff;
}

.serviceDescriptionLine {
  font-weight: 300;
  font-family: "RS";
  padding-bottom: 22px;
  hyphens: auto;
  span {
    font-size: 14px;
    font-family: "RS";
    font-weight: 500;
  }

}

.serviceImg {
  width: 200px;
  height: 160px;
  min-width: 200px;
  min-height: 160px;
  margin-top: -100px;
  margin-bottom: 14px;
}

.serviceButton {
  display: block;
  max-width: 369px;
  margin: 63px auto;

  span {
    font-size: 27.6174px;
    font-weight: 800;
    line-height: 32px;

    color: #ffffff;

    text-shadow: 0px 4.2659px 4.2659px rgba(0, 0, 0, 0.25);
  }
}

@media screen and (max-width: 1230px) {
  .services {
    flex-wrap: wrap;
    justify-content: center;
    align-items: unset;
  }
  .serviceItem {
    width: 45%;
    max-width: 45%;
  }
  .serviceTitle {
    margin-top: 0;
  }
  .serviceDescription {
    line-height: 20px;
    max-width: 330px;
  }
}

@media screen and (max-width: 768px) {
  .serviceItem:nth-child(n + 2) {
    margin-top: 45px;
  }

  .serviceTitle {
    line-height: 25px;
  }

  .serviceDescription {
    min-width: 230px;
  }

  .serviceItem {
    width: 100%;
    max-width: 500px;
    margin-right: 0px;
    max-height: 480px;
  }

  .serviceButton {  
    width: 100%;
    span {
      font-size: 21px;
    }
  }
  .serviceButton {
    margin-top: 28px;
  }
}