.text {
  text-align: center;
  position: relative;
  font-size: 6px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 43.23%,
    #d6d6d4 51.56%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183d58;
  z-index: 3;
  margin-bottom: 15px;
  margin: 16px 0;
}

.feed {
  overflow: hidden;
}

.feedItem {
  text-align: center;
  margin: 0 10px;
  max-height: 80px;
  border-radius: 15px;
  border: 1px solid white;
  padding: 0 5px;
}

.icon {
  max-height: 29px;
  margin-top: 14px;
}