.steps {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 226px;
}

.step {
  position: relative;
  padding: 37px 10px;
  max-width: 340px;
  height: 154px;
  max-height: 154px;
  background: rgba(217, 217, 217, 0.01);
  background-size: 146px 295px;
  border: 1px solid #ffffff;
  border-radius: 29px;
  .stepBackground {
    z-index: -1;
    width: 146px;
    height: 295px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(50px, -50%);
  }
  &:nth-child(2) {
    .stepBackground {
      width: 232px;
      height: 298px;
      transform: translate(12px, -50%);
    }
  }
  &:last-child {
    .stepBackground {
      width: 238px;
      height: 298px;
      transform: translate(64px, -50%);
    }
  }
}

.stepDesc {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "RS";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 1080px) {
  .steps {
    flex-wrap: wrap;
    height: 1000px;
  }

  .step {
    width: 100%;
    max-width: 530px;
    margin: 0 auto 150px;
    // color: #090808;
    // fill: #090808;
    .stepBackground {
      z-index: -1;
      width: 146px;
      height: 295px;
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translate(0px, -50%);
    }
    &:nth-child(2) {
      .stepBackground {
        width: 232px;
        height: 298px;
        transform: translate(125%, -50%);
      }
    }
    &:last-child {
      .stepBackground {
        width: 238px;
        height: 298px;
        transform: translate(0px, -50%);
      }
    }
  }
  .stepDesc {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .steps {
    height: auto;
  }
  .step {
    margin-bottom: 70px;
    font-size: 11.51px;
    font-family: RS;
    line-height: 20.553px;
    .stepBackground {
      width: 89px;
      height: 183px;
    }
    &:nth-child(2) {
      .stepBackground {
        width: 143px;
        height: 184px;
        transform: translate(0, -50%);
        left: unset;
        right: 0;
        top: 50%;
      }
    }
    &:nth-child(3) {
      .stepBackground {
        width: 147px;
        height: 185px;
        transform: translate(0, -50%);
        top: 50%;
      }
    }
  }
}
