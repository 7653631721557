.productHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productHeaderBack {
  display: flex;
}

.productHeaderBackIcon {
  transform: rotate(180deg);
  margin-right: 5px;
}

.productHeaderBackTitle {
  font-weight: 100;
  font-size: 12px;
  color: #fff;
}

.social {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #7148D7;
  padding: 4px 6px 4px 18px;
  // border-image: linear-gradient(167.1deg,#7c53ffcc 1.06%,#c27affcc 92.98%);
  background: linear-gradient(180deg, rgba(49, 49, 49, 0.45) 1.37%, rgba(0, 0, 0, 0.45) 100%), #0a0b0d;
}

.info {
  margin-right: 10px;
}

.tag {
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding-bottom: 2px;
}

.description {
  text-align: center;
  font-weight: 400;
  font-size: 6px;
  color: #535353;
}