.container {
  max-width: 350px;
  padding: 0 15px;
  margin: 0 auto;
  font-size: 8px;
  color: white;
}

.title {
  text-align: center;
  position: relative;
  font-size: 12px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 43.23%,
    #d6d6d4 51.56%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183d58;
  z-index: 3;
  margin-bottom: 15px;
  margin: 16px 0;
}

.titleShadow {
  font-size: 12px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1px;
  z-index: 0;
  color: rgba(67, 49, 121, 1);
  // text-shadow: -5px -5px rgba(67, 49, 121, 1);
  margin-bottom: 15px;
  width: max-content;
  margin: 16px 0;
}

.feed {
  margin-top: 16px;
}