.device {
  margin-bottom: 12px;
}

.deviceIcon {
  width: 20px;
  height: 20px;
}

.deviceInfo {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.deviceTitle {
  font-weight: 400;
  margin-left: 10px;
  font-size: 12px;
  background: linear-gradient(180deg, #fff 49.53%, #a4a4a4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.deviceValue {
  border: 0.50px solid #fff;
  border-radius: 15px;
  padding: 16px 18px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}