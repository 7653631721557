.advantages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  position: relative;
  gap: 1rem;

  &::after {
    content: "";
    right: 349px;
    top: 182px;
    position: absolute;
    width: 106px;
    height: 106px;
    background-image: url('../../images/backgrounds/web.png');
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    right: 240px;
    top: 361px;
    width: 250px;
    height: 250px;
    background: #ffffff;
    opacity: 1;
    filter: blur(281px);
  }
}

@-moz-document url-prefix() {
  .advantages {
    &::before {
      content: "";
      position: absolute;
      right: 240px;
      top: 361px;
      width: 250px;
      height: 250px;
      background: #ffffff;
      opacity: 0.2;
      filter: blur(281px);
    }
  }
}

.advantagesItem {
  position: relative;
  max-width: 390px;
  height: 308px;
  min-height: 308px;
  box-shadow: 0px 58.1116px 45.6591px -25.5968px rgba(29, 32, 33, 0.64);
  // backdrop-filter: blur(86px);
  // -webkit-backdrop-filter: blur(86px);
  background: linear-gradient(180deg, rgba(22, 22, 22, 0.322) 0%, rgba(0, 0, 0, 0.441) 100%);

  border-radius: 16px;
  padding: 30px 20px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
  flex: 1 1 calc((100% / 3) - 2rem);


  &::before {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(176, 149, 255, 0) 0.06%, #161616 92.98%);
  }
  &::after {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.43);
  }
}

.advantagesItemTitle {
  max-width: 320px;
  margin-top: 15px;
  font-family: 'RS';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  /* or 120% */

  text-align: center;

  color: rgba(255, 255, 255, 0.92);

  text-shadow: 0px -5.71492px 22.8597px rgba(0, 0, 0, 0.25);
  // backdrop-filter: blur(142.873px);
}

.advantagesItemDesc {
  font-family: 'RS';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  /* or 179% */

  text-align: center;

  /* White */

  color: #FFFFFF;
  margin-top: 9px;
}

@media screen and (max-width: 1200px) {
  .advantages {
    justify-content: center;
  }
  .advantagesItem {
    flex: 1 1 calc((100% / 2) - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .advantagesItem {
    flex: 1 1 calc((100% / 1) - 2rem);
  }
}

@media screen and (max-width: 400px) {
    .advantagesItem {
      max-height: 231px;
      // height: 231px;
      // min-height: 231px;
    }

    .advantagesItemTitle {
      font-size: 18.821px;
      font-weight: 800;
      line-height: 22.585px;
    }

    .advantagesItemDesc {
      font-size: 10.54px;
      line-height: 18.821px;
    }
}