.admin {
  width: 100%;
  margin-top: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  input {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 0;
  }
}