.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 490px;
  min-width: 490px;
  // padding: 20px 0;
}

.menuItem {
  a {
    text-decoration: none;
    font-family: "RS";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 37px;
    transition: text-shadow 0.15s;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      text-shadow: 0 0 0.9px #fff, 0 0 0.9px #fff, 0 0 0.9px #fff;
    }
  }
}

@media screen and (max-width: 1230px) {
  .menu {
    max-width: 540px;
    width: 540px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .menu {
    display: none;
  }
}
