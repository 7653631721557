.container {
  max-width: 350px;
  padding: 20px 15px;
  margin: 0 auto;
  font-size: 8px;
  color: white;
}

.configBlock {
  margin: 30px 0;
}

.tests {
  margin-top: 26px;
}

.configNotice {
  margin-top: 24px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(180deg, #fff 49.53%, #a4a4a4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  span {
    font-weight: 500;
  }
}

.gift {
  margin-top: 26px;
  .configNotice {
    margin-bottom: 16px;
  }
}

.breakLine {
  margin: 16px 0 26px; 
}