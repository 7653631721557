.mobileMenu {
  padding: 35px;
  text-align: center;
  display: none;
  background: #090808;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow-y: scroll;
}

.mobileMenuLogo {
  margin-bottom: 15px;
  width: 230px;
  height: 190px;
}

.mobileMenuButton {
  margin-top: 20px;
}

.mobileMenuActive {
  display: block;
}

.mobileMenuList {
}

.mobileMenuItem {
  margin-bottom: 25px;
  a {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: RS;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.headerLogoCtn {
  margin-right: 20px;
  position: relative;
  &::before {
    content: "";
    width: 50px;
    height: 47px;
    background-color: white;
    position: absolute;
    left: 62px;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    opacity: 1;
    filter: blur(32px);
  }
}

@media screen and (max-width: 768px) {
  .headerLogoCtn {
    margin-right: 0;
    &::before {
      width: 55px;
      height: 55px;
      left: calc(50% - 28px);
      filter: blur(38px);
      top: 47%;
    }
  }
}