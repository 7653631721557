.productsFilterPrice {
  display: flex;
  align-items: center;
  gap: 1rem;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.productsFilterPriceItem {
  flex: 1 1 calc((100% / 4) - 2rem);
  font-family: 'RS';
  font-style: normal;
  font-weight: 800;
  font-size: 16.2652px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4.53502px 4.53502px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border: 3px solid rgba(62, 62, 62, 0.31);
  // border-image: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);
  background: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);

  border-radius: 12px;
  cursor: pointer;

  &:first-child {
    flex-basis: calc((100% / 4) - 2rem - 270px);
  }
}

.productsFilterPriceItemActive {
  background: linear-gradient(167.1deg, rgba(124, 83, 255, 0.8) 1.06%, rgba(194, 122, 255, 0.8) 92.98%) !important;
  border: 3px solid rgba(255, 255, 255, 0.21);
  box-shadow: 0px -6.09481px 24.3793px -36.5689px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(152.37px);
  /* Note: backdrop-filter has minimal browser support */
}

.productsFilterType {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productsFilterTypeItem {
  min-width: 355px;
  padding: 12px 10px;
  font-family: 'RS';
  font-style: normal;
  font-weight: 800;
  font-size: 16.2652px;
  line-height: 16px;
  text-align: center;

  color: #FFFFFF;
  cursor: pointer;

  text-shadow: 0px 4.53502px 4.53502px rgba(0, 0, 0, 0.25);

  text-shadow: 0px 4.53502px 4.53502px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  // border: 3px solid transparent;
  border-radius: 13px;
  border-image: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);
  // border-image-slice: 1;
  background: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);
  // box-shadow: 0px 61.7778px 48.5397px -27.2116px rgba(29, 32, 33, 0.64);
  // backdrop-filter: blur(22.8564px);
  /* Note: backdrop-filter has minimal browser support */
}

.productsFilterTypeItemActive {
  background: linear-gradient(167.1deg, rgba(124, 83, 255, 0.8) 1.06%, rgba(194, 122, 255, 0.8) 92.98%);
  border: 3px solid rgba(255, 255, 255, 0.21);
  box-shadow: 0px -6.09481px 24.3793px -36.5689px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(152.37px);
  /* Note: backdrop-filter has minimal browser support */
}

.productFilterMobileTitle {
  display: none;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 26px;
  font-family: RS;
  text-align: center;
  // margin-top: 15px;
}

@media screen and (max-width: 1230px) {
  .productsFilterPrice, .productsFilterType {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .productsFilterType {
    margin-top: 30px;
  }

  .productsFilterPriceItem, .productsFilterTypeItem {
    flex: 1 1 calc((100% / 3) - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .productFilterMobileTitle {
    display: block;
  }
  .productsFilterPriceItem {
    flex: 1 1 calc((100% / 1) - 2rem);
    font-family: 'RS';
    font-style: normal;
    font-weight: 800;
    font-size: 16.2652px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4.53502px 4.53502px rgba(0, 0, 0, 0.25);
    padding: 10px 15px;
    border: 3px solid rgba(62, 62, 62, 0.31);
    // border-image: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);
    background: linear-gradient(180deg, rgba(62, 62, 62, 0.31) 0%, rgba(62, 62, 62, 0.1643) 100%);
  
    border-radius: 12px;
    cursor: pointer;
  
    &:first-child {
      flex-basis: calc((100% / 1) - 2rem);
    }
  }
}