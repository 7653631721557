.live {
  margin-top: 53px;
}

.liveItem {
  // position: absolute;
  max-width: 90%;
  height: 440px;
  display: flex !important;
  padding: 8px;
  // left: 708px;
  // top: 6732px;

  box-shadow: 0px 58.1116px 45.6591px -25.5968px rgba(29, 32, 33, 0.64);
  backdrop-filter: blur(21.5px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid rgba(255, 255, 255, 0.92);

  // border-image-source: linear-gradient(167.1deg, rgba(255, 255, 255, 0.92) 1.06%, rgba(255, 255, 255, 0.92) 92.98%);

  border-radius: 34px;
  // transform: matrix(-1, 0, 0, 1, 0, 0);
  outline: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
  }
}

@media screen and (min-width: 2200px) {
  .liveItem {
    height: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .liveItem {
    height: 400px;
  }
}

@media screen and (max-width: 950px) {
  .liveItem {
    // height: 200px;
    img {
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 500px) {
  .liveItem {
    max-width: 95%;
    height: 335px;
  }
}