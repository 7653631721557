.footer {
  margin-top: 83px;
  display: flex;
  justify-content: space-between;
}

.footerData {
  margin-bottom: 13px;
  margin-top: 105px;
  font-family: "RS";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 25px;
  text-align: right;
  /* or 147% */

  /* White */

  color: #ffffff;
  &:last-child {
    margin-top: 0;
  }
}

.footerColumn {
  &:first-child {
    margin-top: 100px;
  }
  &:nth-child(2) {
    position: relative;
    width: 27%;
    &::before {
      content: "";
      width: 78px;
      height: 55px;
      background-color: white;
      position: absolute;
      left: 106px;
      top: 40%;
      z-index: -1;
      transform: translateY(-50%);
      background: #ffffff;
      opacity: 1;
      filter: blur(41px);
    }
  }
}

.footerLogo {
  width: 90%;
  height: 80%;
}

.footerPhone {
  margin-top: 20px;
  font-family: "RS";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  /* or 147% */

  text-align: center;

  /* White */

  color: #ffffff;
}

.footerPhoneItem {
  padding: 20px 0;
  font-family: "RS";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height, or 120% */

  text-align: left;

  /* White */

  color: #ffffff;
}

.footerDocument {
  display: block;
  color: white;
  text-align: right;
  font-size: 17px;
  font-family: RS;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  text-decoration-line: underline;
  margin-bottom: 13px;
  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1100px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footerColumn {
    width: 100%;
    order: 2;
    text-align: center;
    &:first-child {
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      order: 1;
      &::before {
        width: 74px;
        height: 36px;
        left: calc(50% - 36px);
        top: 42%;
        transform: translateY(-50%);
        filter: blur(41px);
      }
    }
    &:nth-child(3) {
      order: 1;
    }
  }
  .footerPhone {
    text-align: center;
    p {
      text-align: center;
    }
  }
  .footerData {
    text-align: center;
    margin-top: 0;
  }
  .footerDocument {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .footerLogo {
    width: 262px;
    height: 235px;
    max-width: none;
    max-height: none;
  }
  .footerColumn {
    width: 100% !important;
    &:first-child {
      margin-top: 15px;
    }
    &:nth-child(2) {
      &::before {
        width: 78px;
        height: 55px;
        left: calc(50% - 40px);
        top: 52%;
        transform: translateY(-50%);
        filter: blur(41px);
      }
    }
  }
  .footerData {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    br:last-child {
      display: none;
    }
    &:last-child {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .footer {
    margin-top: 60px;
  }
  .footerPhone {
    margin-top: 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 40px;
  }
  .footerPhoneItem {
    padding: 10px 0;
  }
  .footerDocument {
    font-size: 13px;
    margin-top: 5px;
  }
}
