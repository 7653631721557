.gifts {
  max-width: 918px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(22, 22, 22, 0.322) 0%,
    rgba(0, 0, 0, 0.441) 100%
  );
  box-shadow: 0px 58.1116px 45.6591px -25.5968px rgba(29, 32, 33, 0.64);
  backdrop-filter: blur(86px);
  /* Note: backdrop-filter has minimal browser support */
  margin-top: 44px;
  padding: 66px 70px;
  border-radius: 33.7033px;

  img {
    margin-top: -40px;
    width: 240px;
    // object-fit: cover;
  }

  &::before {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(
      0deg,
      rgba(176, 149, 255, 0) 0.06%,
      rgba(22, 22, 22, 1) 92.98%
    );
  }
  &::after {
    transition: all 0.2s;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.43);
  }
}

.giftsListItem {
  font-family: "RS";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 156.79%;
  /* or 22px */

  color: #ffffff;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
}

.giftsDesc {
  margin-top: 31px;
  max-width: 462px;
  font-family: "RS";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 156.79%;
  /* or 22px */

  color: #a9a9a9;
}

.giftsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 296px;
  height: 67px;
  margin: 48px auto;
  span {
    font-size: 26px;
    margin-bottom: 3px;
    font-weight: 800;
    line-height: 32px;
    color: #ffffff;
    text-shadow: 0px 4.2659px 4.2659px rgba(0, 0, 0, 0.25);
  }
}

@media screen and (max-width: 860px) {
  .gifts img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .gifts {
    flex-wrap: wrap-reverse;
    padding: 10px 19px;
    text-align: center;
    max-width: 500px;
  }
  .giftsListItem {
    color: #FFF;
    font-size: 14px;
    font-family: RS;
    line-height: 156.792%;
  }
  .gifts img {
    display: block;
    margin: 0 auto;
  }
  .gifts div {
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .giftsDesc {
    // text-align: center;

    margin: 15px auto 0;
  }

  .giftsButton {
    display: none;
  }
}
