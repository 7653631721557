.blur {
  position: absolute;
  top: -15px;
  // opacity: 0.85;
}

.product {
  width: 140px;
  min-width: 140px;
  position: relative;
  overflow: hidden;
}

.productWidth {
  width: 140px;
  min-width: 140px;
  position: relative;
}

.productTitle {
  text-align: center;
  position: relative;
  font-size: 16px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 43.23%,
    #d6d6d4 51.56%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183d58;
  z-index: 3;
  margin-bottom: 15px;
}

.productTitleShadow {
  font-size: 16px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1px;
  z-index: 0;
  color: rgba(67, 49, 121, 1);
  // text-shadow: -5px -5px rgba(67, 49, 121, 1);
  margin-bottom: 15px;
  width: max-content;
}

.productContainer {
  position: relative;
  border-radius: 10px;
  background: rgba(21, 21, 21, 0.20);
  // box-shadow: 0px 40px 45.659122467041016px -25.596782684326172px rgba(29, 32, 33, 0.64);
  backdrop-filter: blur(21.5px);
  width: 140px;
  max-width: 140px;
  max-height: 221px;
  height: 221px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 216px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), black 65%, black);
    bottom: 0;
    left: 0;
    opacity: 0.85;
    z-index: 10;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: transparent;
    width: calc(100%);
    height: calc(100% + 2px);
    z-index: -10;
    border: 1px solid #7C53FF;
    border-radius: 10px;
  }
}

.productContainer {
  background-size: contain;
  overflow: hidden;
  // background-image: url("../../images/products/grid.png");
  background-repeat: no-repeat;
  // opacity: 1;
  // background-color: rgba(0, 0, 0, 0.20);
}

// .productPlanet {
//   position: absolute;
//   right: 0;
//   top: 0px;
//   background-image: url("../../images/products/globe.png");
//   background-repeat: no-repeat;
//   width: 78px;
//   height: 216px;
//   z-index: -11;
// }

// .productWide {
//   position: absolute;
//   left: 0;
//   top: 128px;
//   background-image: url("../../images/products/wide.png");
//   background-repeat: no-repeat;
//   width: 53px;
//   height: 216px;
//   z-index: -11;
//   opacity: 0.7;
// }

.productImageContainer {
  text-align: center;
  max-height: 94px;
  position: relative;
  &::before {
    content: "";
    width: 124px;
    height: 44px;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    border-radius: 100%;
    background: #111;
    border: 1px solid #7047d6;
    z-index: 3;
  }
  &::after {
    background: #3E2F8C;
    content: "";
    filter: blur(72px);
    height: 115px;
    opacity: 1;
    position: absolute;
    z-index: 3;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    width: 115px;
  }
}

.productImage {
  position: relative;
  z-index: 10;
  margin-top: 15px;
  max-height: inherit;
}

.productStats {
  position: relative;
  padding: 0 36px;
  // margin-top: -115px;
  z-index: 15;
}

.productStatsTitle {
  color: rgba(255, 255, 255, 0.92);
  text-align: center;
  text-shadow: 0px -1.0852025747299194px 4.340810298919678px -6.511214256286621px
    rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-family: RS;
  font-weight: 800;
  line-height: 28.578px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.productPrice {
  text-align: center;
  position: relative;
  font-size: 24px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 43.23%,
    #d6d6d4 51.56%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183d58;
  z-index: 3;
  margin-top: 9px;
  max-width: 185px;
  margin: 15px auto 0;
  &::before {
    content: '';
    width: 100%;
    height: calc(100% + 12px);
    position: absolute;
    left: 0;
    top: -6px;
    background: linear-gradient(to right, gray 2px, transparent 2px) 0 0, 
    linear-gradient(to right, gray 2px, transparent 2px) 0 100%, 
    linear-gradient(to left, gray 2px, transparent 2px) 100% 0, 
    linear-gradient(to left, gray 2px, transparent 2px) 100% 100%, 
    linear-gradient(to bottom, gray 2px, transparent 2px) 0 0, 
    linear-gradient(to bottom, gray 2px, transparent 2px) 100% 0, 
    linear-gradient(to top, gray 2px, transparent 2px) 0 100%, 
    linear-gradient(to top, gray 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
}

.productPriceShadow {
  font-size: 24px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 3px;
  z-index: 0;
  color: rgba(67, 49, 121, 1);
  // text-shadow: -5px -5px rgba(67, 49, 121, 1);
  margin-bottom: 15px;
}

.productPriceContainer {
  position: relative;
}

.productCredit {
  display: block;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;
}

.productCreditTitle {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  font-family: RS;
  line-height: 127.2%;
}

.productCreditPrice {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-family: RS;
  font-weight: 800;
  line-height: 127.2%;
  text-transform: uppercase;
  margin-top: 3px;
}

.productBuy {
  width: 100%;
  text-align: center;
  margin-top: 12px;
}

.productTitleContainer {
  position: relative;
  z-index: 11;
  margin-top: -14px;
}

.productDevices {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 6px;
  z-index: 12;
}

.productDevicesItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 7px;
  color: #b4b4b4;
  svg {
    margin-bottom: 2px;
  }
}

.productDevicesName {
  font-weight: 600;
  color: white;
}

.productDevicesLine {
  margin: 3px 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, rgba(124, 83, 255, 0.8) 1%, rgba(194, 122, 255, 0.8) 93%);
  border-radius: 1px;
}

.productDevicesPrices {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  z-index: 11;
  margin-top: 10px;
}

.productDevicesPricesOld {
  position: relative;
  font-weight: 400;
  font-size: 8px;
  text-decoration: underline;
  background: linear-gradient(178deg, rgba(124, 83, 255, 0.8) 1.06%, rgba(194, 122, 255, 0.8) 92.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &::before {
    content: '';
    top: 50%;
    transform: translateY(-50%);
    background: #909090;
    position: absolute;
    width: 100%;
    height: 0.5px;
  }
}

.productDevicesPricesCurrent {
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(180deg, #fff 49.53%, #a4a4a4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.productDevicesCredit {
  position: relative;
  z-index: 11;
  margin-top: 4px;
  text-align: center;
}

.productDevicesCreditText {
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 5px;
  color: rgba(255, 255, 255, 0.8);
}

.productDevicesCreditValue {
  font-weight: 800;
  font-size: 6px;
  text-transform: uppercase;
  color: #fff;
}

.productDevicesButton {
  display: flex;
  justify-content: center;
  margin: 7px auto 0;
  width: calc(100% - 13px);
  text-align: center;
  padding: 3px 0;
  z-index: 11;
  &::after {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1.5px solid rgba(255, 255, 255, 0.21);
  }
  span {
    font-size: 8px;
  }
}