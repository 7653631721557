.h2 {
  font-family: 'RS';
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 47px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
}

.h3 {
  font-family: 'RS';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 37px;
  text-align: center;
  /* identical to box height */
  color: #FFFFFF;
}