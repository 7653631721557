.socilas {
  max-width: 912px;
  margin: 70px auto 0;
  padding: 16px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, rgba(35, 35, 35, 1) 5px, transparent 2px) 0 0, 
  linear-gradient(to right, rgba(35, 35, 35, 1) 5px, transparent 2px) 0 100%, 
  linear-gradient(to left, rgba(35, 35, 35, 1) 5px, transparent 2px) 100% 0, 
  linear-gradient(to left, rgba(35, 35, 35, 1) 5px, transparent 2px) 100% 100%, 
  linear-gradient(to bottom, rgba(35, 35, 35, 1) 5px, transparent 2px) 0 0, 
  linear-gradient(to bottom, rgba(35, 35, 35, 1) 5px, transparent 2px) 100% 0, 
  linear-gradient(to top, rgba(35, 35, 35, 1) 5px, transparent 2px) 0 100%, 
  linear-gradient(to top, rgba(35, 35, 35, 1) 5px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 53px 45px;
}

.socialsItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 110px;
  min-width: 148px;
  min-height: 110px;
  background: linear-gradient(
    180deg,
    rgba(86, 86, 86, 0.322) 0%,
    rgba(35, 35, 35, 0.441) 100%
  );
  backdrop-filter: blur(86px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 33.7033px;

  &:nth-child(3) {
    background: linear-gradient(
      180deg,
      rgba(255, 0, 0, 0.441) 0%,
      rgba(164, 2, 2, 0.322) 100%
    );
  }
}

.socialItemDesc {
  min-width: 169px;
  position: absolute;
  bottom: -34px;
  font-family: "RS";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 156.79%;
  /* or 13px */

  text-align: center;

  color: #8e8e8e;
}

@media screen and (max-width: 966px) {
  .socilas {
    gap: 1rem;
    flex-wrap: wrap;
    width: 693px;
  }
  .socialsItem {
    flex: 1 1 calc((100% / 3) - 2rem);
    &:nth-child(2) {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .socilas {
    width: auto;
    margin-top: 5px;
    max-width: 500px;
  }
  .socialsItem {
    flex: 1 1 calc((100% / 2) - 2rem);
    width: 102px;
    min-width: 102px;
    height: 92px;
    min-height: 92px;
    svg {
      width: 56px;
      height: 56px;
    }
    &:nth-child(1) svg {
      width: 80px;
      height: 60px;
    }
    &:nth-child(3) {
      order: 2;
    }
    &:nth-child(2) {
      flex-grow: 0;
      margin: 0 auto;
      order: 5;
      margin-bottom: 25px;
    }
  }
}