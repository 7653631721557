.titleBox {
  position: relative;
}

.title {
  text-align: center;
  font-size: 12px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 43.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183d58;
  z-index: 3;
  margin-bottom: 15px;
  margin: 16px 0;
}

.titleShadow {
  font-size: 12px;
  font-family: RS;
  font-weight: 800;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1px;
  z-index: -1;
  color: rgba(67, 49, 121, 1);
  // text-shadow: -5px -5px rgba(67, 49, 121, 1);
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}